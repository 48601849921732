.focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}
.disabled\:opacity-50:disabled {
  opacity: 0.5;
}

.switch-parent[data-state="checked"] {
  background-color: #00E48D !important;
  border: none;
}

.switch-parent[data-state="unchecked"] {
  background-color: #E4E4E7;
  border: 1px solid rgba(0, 0, 0, 0.05)
}

.switch-thumb[data-state="checked"] {
  transform: translateX(0.9rem);
}

.switch-thumb[data-state="unchecked"] {
  transform: translateX(-0.3rem);
}