.calendar {
  /* width: 900px; */
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.calendar-header {
  /* height: 80px; */
  width: 100%;
  display: flex;
  align-items: center;
  flex: auto;
  color: #030712
}

.calendar-header p {
  font-weight: 600;
  font-size: 16px;
}

.calendar-body {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.table-header {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0.5px solid #e7e7e7c3;
}

.weekday {
  width: 100px;
  text-align: left;
  font-weight: 500;
  /* color:#000000c0; */
  color: #9ca3af
}

.table-content {
  width: 100%;
  flex-grow: 1;
  display: grid;
  /* flex-wrap: wrap; */
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: '0';
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all;
  transition-duration: 300ms;
}

.calendar-day {
  width: 100%;
  height: auto;
  aspect-ratio: 10 / 9;
  position: relative;
  border: 0.5px solid #e7e7e7c3;
  transition: all;
  transition-duration: 300ms;
}

.calendar-day:hover {
  background-color: #23c66729;
  border: 0.5px solid #23c66750;
}

.calendar-day p {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #d1d5db;
}

.current p {
  color: #374151;
}

.selected {
  background-color: #88eeb31b;
  border: 0.5px solid #23c667;
}

.selected p {
  color: #23c667;
  font-weight: bold;
}